<template>
  <nav class="col-md-3 col-lg-2">
        <div class="logo">
      <img
        src="@/assets/admin/logo.jpg"
        alt="Logomarca"
        width="150"
        height="180"
      />
    </div>
    <ul class="nav flex-column">
      <li class="nav-item" v-for="(item, id) in items" :key="id">
        <router-link aria-current="page" :to="routes[id]" @click="removeUpdatable">
          {{ item }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    items: {
      type: Array,
    },
    routes: {
      type: Array,
    },
  },
  methods: {
    removeUpdatable() {
      sessionStorage.removeItem("updateProfileId");
    }
  }
};
</script>

<style scoped>
.nav {
  width: 220px;
  padding: 25px;
}

.nav-item {
  list-style: none;
  font-size: 20px;
  color: #193250;
}

.nav-item:last-child {
  margin-top: 3px;
}

a {
  text-decoration: none;
  color: black;
}

a:hover,
a:active {
  color: #0091f1;
}
.logo {
  position: absolute;
  height: 20%;
  bottom: 0;
  margin-left: 40px;
  margin-bottom: 55px;
}
</style>
