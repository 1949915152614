<template>
 
  <Header />
   <div class="container">
    <div class="row">
      <Sidebar
        class="col-md-3 col-lg-2"
        :items="['Início', 'Cadastros', '- Usuário', '- Comunidade']"
        :routes="['/admin', '/admin/registers', '/admin/new', '/admin/comunity']"
      />
      <div class="col-md-9 col-lg-10 px-md-4 main">
        <div class="row mt-3">
          <div class="col">
            <h4 style="margin-top: 20px;">CADASTROS</h4>
          </div>
          <div class="col text-end">
            <h4 class="text2" style="margin-top: 20px;">
              Inicial / <span style="color: #f8af2f;">Cadastros</span>
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <router-link to="/admin/new" class="button new" @click="removeUpdatable">
              Novo Cadastro
            </router-link>
          </div>
        </div>
        <div class="col mt-3">
          <h5>Cadastros</h5>
        </div>
        <table class="table mt-4">
          <tbody>
            <tr v-for="(item, id) in list" :key="id">
              <td>
                {{ item.attributes.FirstName + " " + item.attributes.LastName }}
              </td>
              <td class="d-flex justify-content-end">
                <span class="material-icons mx-5">
                  <a href="" style="color: #00c0c9" @click.prevent="update(item.id)">
                    edit
                  </a>
                </span>
                <span class="material-icons">
                  <a href="" style="color: #cf3900" @click="remove(item.id)">
                    delete
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="page navigation">
          <ul class="pagination d-flex justify-content-end">
            <li
              class="page-item"
              v-for="item in offsetLimitForPaginaition"
              :key="item"
            >
              <a
                class="page-link"
                href="#"
                @click="getPage(item)"
                v-bind:class="{ active: item === currentPage }"
              >
                {{ item }}
              </a>
            </li>
            <li class="page-item" v-if="offset > 3">
              <a class="page-link" href="#">Próximo</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

import Profile from "@/services/profile";
import Image from "@/services/upload";

export default {
  name: "Registers",
  components: { Sidebar, Header },
  data() {
    return {
      total: 0,
      limit: 10,
      offset: 0,
      offsetLimitForPaginaition: 3,
      currentPage: 1,
      list: [],
    };
  },
  mounted() {
    Profile.all()
      .then((response) => {
        if (response.data.data) {
          /*this.total = response.data.meta.pagination.total;
          this.limit = response.data.meta.pagination.pageSize;
          this.offset = response.data.meta.pagination.pageCount;
          this.currentPage = response.data.meta.pagination.page;*/
          this.list = response.data.data;

          if (this.offset <= 3) this.offsetLimitForPaginaition = this.offset;
        }
      })
      .catch((error) => {
        if (!error.response)
          alert("Erro ao tentar se conectar com o servidor.");
      });
  },
  methods: {
    remove(id) {
      var result = confirm("Deseja realmente excluir o cadastro?");
      if (result) {
        Profile.remove(id)
          .then((response) => {
            if (response.data.data.attributes.Photo.data)
              Image.remove(response.data.data.attributes.Photo.data.id)
                .catch((error) => {
                  console.log("Erro ao remover imagem: " + error);
                });
          })
          .catch((error) => {
            console.log("Erro ao remover profile: " + error);
          });
      }
    },
    update(id) {
      sessionStorage.setItem("updateProfileId", id);
      this.$router.push("/admin/new");
    },
    getPage(item) {
      this.currentPage = item;

      Profile.getPage(this.currentPage, this.limit)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.list = response.data.data;
          }
        })
        .catch((error) => {
          console.log("Erro ao recuperar paginação: " + error);
        });
    },
    removeUpdatable() {
      sessionStorage.removeItem("updateProfileId");
    }
  },
};
</script>

<style scoped>
.container {
  min-width: 100%;
}
.main {
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: rgb(223, 232, 240);
  min-height: 80vh;
}

h4,
h5 {
  font-style: normal;
  font-family: Ubuntu;
  line-height: normal;
  font-weight: 500;
}

h5 {
  font-size: 18px;
  color: #828282;
}

.text2 {
  font-size: 16px;
  color: #828282;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.new {
  display: flex;
  width: 170px;
  height: 35px;
  border-radius: 18px;
  font-size: 18px;
  font-weight: 300;
}

.new:hover {
  background: #f8af2f;
}

a {
  text-decoration: none;
}

tr:hover {
  border: 1px solid #f8af2f;
}

.page-item .page-link {
  border-radius: 18px;
  background: #f19700;
  color: white;
}

.page-link:hover {
  background: #f8af2f;
}

.active {
  background: #193250 !important;
}
</style>
