import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    upload: async (form) => {
        return await HTTP.post("upload", form, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        }); 
    },

    remove: async (imageId) => {
        return await HTTP.delete(`upload/files/${imageId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        }); 
    },
}