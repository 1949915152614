<template>
  <nav class="navbar navbar-light">
    <div class="d-flex">
      <router-link to="/admin" class="navbar-brand">
        <!-- <img
          src="@/assets/admin/logo.jpg"
          alt="Logomarca"
          width="50"
          height="60"
          class="logo"
        /> -->
        <h1 class="text-white px-4">Associadas</h1>
      </router-link>
      <form class="my-auto">
          <div class="form-group has-search search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" placeholder="Buscar">
          </div>
      </form>
    </div>
    <div class="d-flex justify-content-end">
      <p class="me-3 my-auto text-white">Olá, {{ username }}</p>
      <a href="/" @click="logout" class="exit"> <i class="bi bi-box-arrow-right"></i>Sair</a>
    </div>
  </nav>
</template>

<script>
import User from "@/services/user";

export default {
  name: "Header",
  data() {
    return {
      username: null
    };
  },
  mounted() {
    this.username = this.$store.getters.getUser.username.toUpperCase();
  },
  methods: {
    logout() {
      User.logout();
    },
  },
};
</script>

<style scoped>
.navbar{
  max-width: 100vw;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  background-color: #033f6d;
}
.logo {
  margin-left: 30px;
}

.exit {
  padding: 5px;
  margin-right: 18px;
  text-decoration: none;
  color: #ffa200;
}

.search {
  margin-left: 4em;
}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>
